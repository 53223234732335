import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import getUserDetails from '@/composables/user/getUserDetails';
import translator from "@/utils/translator";

function isTokenExpired(token) {
    if (!token) return true;
    const payloadBase64 = token.split('.')[1];
    const decodedJson = atob(payloadBase64);
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp * 1000; // Konwersja na milisekundy
    const now = new Date();
    return now.getTime() > exp;
}

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token && isTokenExpired(token)) {
        localStorage.removeItem('token');
        delete config.headers.Authorization;
        router.push('/login');
        throw new Error('Session expired');
    }
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        router.push('/login');
        return Promise.reject('Session expired, please log in again.');
    }
    return Promise.reject(error);
});

const app = createApp(App);

function initializeUserLanguage() {
    let userLanguage = localStorage.getItem('user_language');

    if (!userLanguage) {
        const browserLanguage = navigator.language || navigator.userLanguage;
        const languageCode = browserLanguage.slice(0, 2).toUpperCase();

        userLanguage = ['en', 'pl'].includes(languageCode) ? languageCode : 'en';

        localStorage.setItem('user_language', userLanguage);
    }
}

initializeUserLanguage();

router.beforeEach(async (to, from, next) => {
    const token = localStorage.getItem('token');
    if (token && !isTokenExpired(token)) {
        try {
            const userDetails = await getUserDetails();
            const darkModeSetting = userDetails.dark_mode;
            const languageSetting = userDetails.language;

            localStorage.setItem('dark_mode', darkModeSetting ? 'true' : 'false');

            localStorage.setItem('user_language', languageSetting);
            translator.changeLanguage(languageSetting);

            if (darkModeSetting) {
                document.documentElement.setAttribute("data-bs-theme", "dark");
            } else {
                document.documentElement.setAttribute("data-bs-theme", "light");
            }
        } catch (error) {
            console.error('Failed to fetch user details:', error);
        }
    }
    next();
});

app.use(router);
app.use(store);

app.config.globalProperties.$translator = translator;

app.mount('#app');
