import { API_BASE_URL } from '@/constants/constants';

async function deleteInjections(injectionId) {
    const token = localStorage.getItem('token');
    return await fetch(API_BASE_URL + '/api/injections/' + injectionId, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
}

export default deleteInjections
