<template>
  <div>
    <h1>
      Stock
      <button type="button" class="btn btn-success btn" data-bs-toggle="modal" data-bs-target="#addStockModal">
        +
      </button>
    </h1>

    <div class="modal fade" id="addStockModal" tabindex="-1" aria-labelledby="addStockModalLabel"
         aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addStockModalLabel">New stock</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p v-if="valid_success.message" class="valid-success">{{ valid_success.message }}</p>
            <div class="mb-3">
              <label for="companyInput" class="form-label">company</label>
              <input type="text" class="form-control" id="companyInput" v-model="stock_company" required>
              <p v-if="errors.stock_company" class="valid-error">{{ errors.stock_company }}</p>
            </div>
            <div class="mb-3">
              <label for="dateInput" class="form-label">date</label>
              <input type="date" class="form-control" id="dateInput" v-model="stock_date" required>
              <p v-if="errors.stock_date" class="valid-error">{{ errors.stock_date }}</p>
            </div>
            <div class="mb-3">
              <label for="terminatedInput" class="form-label">terminated</label>
              <input type="date" class="form-control" id="terminatedInput" v-model="stock_terminated_date" required>
              <p v-if="errors.stock_terminated_date" class="valid-error">{{
                  errors.stock_terminated_date
                }}</p>
            </div>
            <div class="mb-3">
              <label for="unitsInput" class="form-label">units</label>
              <input type="number" class="form-control" id="unitsInput" v-model="stock_units" placeholder="90000"
                     required>
              <p v-if="errors.stock_units" class="valid-error">{{ errors.stock_units }}</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" @click="validateForm" class="btn btn-success">Create</button>
            <!--            <button type="submit" @click="validateForm" class="btn btn-success" data-bs-dismiss="modal">Create</button>-->
          </div>
        </div>
      </div>
    </div>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Units</th>
        <th scope="col">Name</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="stock in stocks" :key="stock.id">
        <td>
          <button type="button" class="btn btn-link btn-sm" data-bs-toggle="modal"
                  :data-bs-target="'#detailsStockModal_' + stock.id">
            <span v-if="!stock.allUnitsUsed">[{{ stock.availablePortions }}] - <b>{{
                stock.usedUnits
              }}</b>/{{ stock.numberOfUnits }}</span>
            <span v-else>{{ stock.created }} (delivery)</span>
          </button>

          <div class="modal fade" :id="'detailsStockModal_' + stock.id" tabindex="-1"
               aria-labelledby="detailsStockModalLabel"
               aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="detailsStockModalLabel">{{ stock.company }}</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <!--                  <p>Recommended to place an order: <u><b>{{ stock.RECOMMDED  }}</b></u></p>-->
                  <p>Available portions: <b>{{ stock.availablePortions }}</b></p>
                  <p v-if="!stock.allUnitsUsed">Will be empty: <b>{{ stock.predictWhenStockWillBeEmpty }}</b></p>
                  <hr>
                  <p>Delivery: <b>{{ stock.created }}</b></p>
                  <p>Number of units: <b>{{ stock.numberOfUnits }}</b></p>
                  <p>Used units: <b>{{ stock.usedUnits }}</b></p>
                  <p v-if="!stock.allUnitsUsed">Terminated date: <b>{{ stock.terminatedDate }}</b></p>
                  <p>Last injection from this stock: <b>{{ stock.lastInjectionFromStock }}</b></p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
                </div>
              </div>
            </div>
          </div>

        </td>

        <td>
          <span class="terminated-date" v-if="stock.warningTerminated">{{ stock.company }}</span>
          <span class="will-be-empty" v-else-if="stock.warningWillBeEmpty && !stock.allUnitsUsed">{{
              stock.company
            }}</span>
          <span v-else>{{ stock.company }}</span>
        </td>

        <td v-if="!stock.allUnitsUsed">

          <div class="dropdown">
            <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
              More
            </button>
            <ul class="dropdown-menu">
              <li>
                <button
                    type="button"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    :data-bs-target="'#updateStockModal_' + stock.id"
                    @click="initializeEditStock(stock)">
                  Edit
                </button>
              </li>
              <li>
                <button
                    type="button"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    :data-bs-target="'#deleteStockModal_' + stock.id">
                  Delete
                </button>
              </li>
            </ul>
          </div>

          <div class="modal fade"
               :id="'updateStockModal_' + stock.id"
               tabindex="-1"
               aria-labelledby="updateStockModalLabel"
               aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="updateStockModalLabel">Edit Stock</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                  <div class="mb-3">
                    <label for="editCompanyInput" class="form-label">Company</label>
                    <input
                        type="text"
                        class="form-control"
                        id="editCompanyInput"
                        v-model="edit_stock.stock_company"
                        required
                    >
                    <p v-if="errors.stock_company" class="valid-error">{{ errors.stock_company }}</p>
                  </div>
                  <div class="mb-3">
                    <label for="editDateInput" class="form-label">Date</label>
                    <input
                        type="date"
                        class="form-control"
                        id="editDateInput"
                        v-model="edit_stock.stock_date"
                        required
                    >
                    <p v-if="errors.stock_date" class="valid-error">{{ errors.stock_date }}</p>
                  </div>
                  <div class="mb-3">
                    <label for="editTerminatedInput" class="form-label">Terminated Date</label>
                    <input
                        type="date"
                        class="form-control"
                        id="editTerminatedInput"
                        v-model="edit_stock.stock_terminated_date"
                        required
                    >
                    <p v-if="errors.stock_terminated_date" class="valid-error">{{ errors.stock_terminated_date }}</p>
                  </div>
                  <div class="mb-3">
                    <label for="editUnitsInput" class="form-label">Units</label>
                    <input
                        type="number"
                        class="form-control"
                        id="editUnitsInput"
                        v-model="edit_stock.stock_units"
                        placeholder="90000"
                        required
                    >
                    <p v-if="errors.stock_units" class="valid-error">{{ errors.stock_units }}</p>
                  </div>

                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-primary" @click="patchStocks(stock.id)" data-bs-dismiss="modal">
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade"
               :id="'deleteStockModal_' + stock.id"
               tabindex="-1"
               aria-labelledby="deleteStockModalLabel"
               aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="deleteStockModalLabel">Delete Stock</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <p style="font-size: large"><b>Are you sure you want to delete this stock:</b></p>
                  <p><b>{{ stock.company }}</b></p>
                  <p><b>{{ stock.created }}</b></p>
                  <p><b>{{ stock.stock_terminated_date }}</b></p>
                  <p><b>{{ stock.numberOfUnits }}j. (used: {{ stock.usedUnits }}j.)</b></p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-danger" @click="deleteStocks(stock.id)" data-bs-dismiss="modal">
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>

        </td>
        <td v-else>
          <b>USED</b>
        </td>
      </tr>
      </tbody>
    </table>

    <div v-if="this.errors.delete_stock" class="alert alert-danger mt-3">{{ this.errors.delete_stock }}</div>
    <div v-if="this.valid_success.update" class="alert alert-success mt-3">{{ this.valid_success.update }}</div>

  </div>
</template>

<script>
import postStocks from "@/composables/stock/postStocks";
import getStocks from "@/composables/stock/getStocks";
import deleteStocks from "@/composables/stock/deleteStocks";
import patchStocks from "@/composables/stock/patchStocks";
import {formatDate} from "@/utils/dateFormatter";

export default {
  data() {
    return {
      stocks: [],
      edit_stock: {
        stock_id: null,
        stock_company: null,
        stock_date: null,
        stock_terminated_date: null,
        stock_units: null,
      },
      stock_company: null,
      stock_date: null,
      stock_terminated_date: null,
      stock_units: null,
      today: new Date().toLocaleDateString(),
      errors: {},
      valid_success: {},
    }
  },
  methods: {
    postStocks() {
      postStocks({
        company: this.stock_company,
        created: this.stock_date,
        terminatedDate: this.stock_terminated_date,
        numberOfUnits: this.stock_units
      })
          .then(() => {
            getStocks()
                .then(result => this.stocks = result)
                .catch(err => console.log(err.message))
            this.stock_company = null
            this.stock_date = null
            this.stock_terminated_date = null
            this.stock_units = null
          })
          .catch(err => console.log(err.message))
    },
    patchStocks(stockId) {
      patchStocks(stockId, {
        company: this.edit_stock.stock_company,
        created: this.edit_stock.stock_date,
        terminatedDate: this.edit_stock.stock_terminated_date,
        numberOfUnits: this.edit_stock.stock_units
      })
          .then(() => {
            getStocks()
                .then(result => this.stocks = result)
                .catch(err => console.log(err.message))
            this.edit_stock.stock_id = null
            this.edit_stock.stock_company = null
            this.edit_stock.stock_date = null
            this.edit_stock.stock_terminated_date = null
            this.edit_stock.stock_units = null
            this.valid_success.update = 'Stock edited successfully'
          })
          .catch(err => console.log(err.message))
    },
    deleteStocks(stockId) {
      deleteStocks(stockId)
          .then(() => {
            getStocks()
                .then(result => this.stocks = result)
                .catch(err => console.log(err.message))
          })
          .catch(err => {
            if (err.response && err.response.status === 500) {
              this.errors.delete_stock = "Delete failed";
            } else if (err.response.status === 406) {
              this.errors.delete_stock = "Injections already exists in this stock"
            } else {
              console.error("Unexpected error:", err);
            }
          });
    },
    initializeEditStock(stock) {
      this.edit_stock.stock_id = stock.id;
      this.edit_stock.stock_company = stock.company;
      this.edit_stock.stock_date = formatDate(stock.created);
      this.edit_stock.stock_terminated_date = formatDate(stock.terminatedDate);
      this.edit_stock.stock_units = stock.numberOfUnits;
    },
    validateForm() {
      this.errors = {};
      this.valid_success = {};
      if (!this.stock_company) this.errors.stock_company = "Company name is required.";
      if (!this.stock_date) this.errors.stock_date = "Date is required.";
      if (!this.stock_terminated_date) this.errors.stock_terminated_date = "Terminate date is required.";
      if (!this.stock_units) this.errors.stock_units = "Units are required.";

      if (Object.keys(this.errors).length === 0) {
        this.postStocks();
        this.valid_success.message = "Stock was added"
      }
    },
  },
  created() {
    getStocks()
        .then(result => this.stocks = result)
        .catch(err => console.log(err.message))
  }
};
</script>

<style>
.valid-error {
  color: red
}

.valid-success {
  color: green;
}

.terminated-date {
  color: red;
}

.will-be-empty {
  color: orange;
}
</style>
