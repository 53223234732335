import { createStore } from "vuex";

export default createStore({
    state: {
        isAuthenticated: !!localStorage.getItem('token'),
        username: localStorage.getItem('username') || '',
    },
    mutations: {
        setAuthentication(state, isAuthenticated) {
            state.isAuthenticated = isAuthenticated;
        },
        setUsername(state, username) {
            state.username = username;
        },
    },
    actions: {
        login({ commit }, username) {
            commit('setAuthentication', true);
            commit('setUsername', username);
            localStorage.setItem('username', username);
        },
        logout({ commit }) {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            commit('setAuthentication', false);
            commit('setUsername', '');
        },
    },
    getters: {
        isAuthenticated: state => state.isAuthenticated,
        username: state => state.username,
    },
});
