function formatDate(date) {
    if (!date) return '';
    const parts = date.split('.');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    return `${year}-${month}-${day}`;
}

export {formatDate};
