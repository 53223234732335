<template>
  <nav v-if="isLoggedIn" class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
<!--      <a class="navbar-brand">Factor use</a>-->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" :to="{ name: 'injection' }" @click="closeMenu">Injection</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" :to="{ name: 'stroke' }" @click="closeMenu">Stroke</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" :to="{ name: 'stock' }" @click="closeMenu">Stock</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{ username.toUpperCase() }}
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item" :to="{ name: 'panel' }" @click="closeMenu">Panel</router-link>
              </li>
              <li><a class="dropdown-item" href="#" @click.prevent="logout">Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view/>
</template>

<script>
import { onMounted, onUnmounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { isTokenExpired } from "@/utils/authUtils";

export default {
  methods: {
    closeMenu() {
      const navbarToggler = document.querySelector('.navbar-toggler');
      const navbarCollapse = document.querySelector('.navbar-collapse');

      if (navbarToggler && navbarCollapse.classList.contains('show')) {
        navbarToggler.click();
      }
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const isLoggedIn = computed(() => store.getters.isAuthenticated);
    const username = computed(() => store.getters.username);

    const checkTokenValidity = () => {
      const token = localStorage.getItem('token');
      if (!token || isTokenExpired(token)) {
        if (store.getters.isAuthenticated) {
          logout();
        }
      }
    };

    const logout = () => {
      store.dispatch('logout');
      router.push('/login');
    };

    watch(() => router.currentRoute.value, () => {
      checkTokenValidity();
    }, {immediate: true});

    onMounted(() => {
      checkTokenValidity();
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    const interval = setInterval(checkTokenValidity, 60 * 1000);

    return {
      logout,
      isLoggedIn,
      username
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
}
</style>
