<template>
  <div>
    <h1>
      Injection
      <button type="button" class="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#addInjectionModal">
        +
      </button>
    </h1>

    <div class="modal fade" id="addInjectionModal" tabindex="-1" aria-labelledby="addInjectionModalLabel"
         aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addInjectionModalLabel">New Injection</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p v-if="valid_success.message" class="valid-success">{{ valid_success.message }}</p>

            <div class="mb-3">
              <label for="unitsInput" class="form-label">units</label>
              <input type="number" class="form-control" id="unitsInput" v-model="injection_units" value="2000">
              <p v-if="errors.injection_units" class="valid-error">{{ errors.injection_units }}</p>
            </div>

            <div class="mb-3">
              <label for="reasonInput" class="form-label">Reason</label>
              <select class="form-select" aria-label="Reason select" id="reasonInput"
                      v-model="injection_reason"
                      :disabled="isDisabled">
                <option v-for="reason in reasons" :value="reason.id" :key="reason.id">
                  {{ reason.title }}
                </option>
              </select>
              <p v-if="errors.injection_reason" class="valid-error">{{ errors.injection_reason }}</p>
            </div>

            <div class="mb-3">
              <label for="reasonDescriptionInput" class="form-label">reason description</label>
              <input type="text" class="form-control" id="reasonDescriptionInput"
                     v-model="injection_reason_description">
              <p v-if="errors.injection_reason_description" class="valid-error">{{
                  errors.injection_reason_description
                }}</p>
            </div>

            <div class="mb-3">
              <label for="stockInput" class="form-label">stock</label>
              <select class="form-select" aria-label="Stock select" id="stockInput" v-model="injection_stock">
                <option v-for="stock in stocks" :value="stock.id" :key="stock.id">
                  [{{ (stock.numberOfUnits - stock.usedUnits) / 2000 }}] {{ stock.company }}
                </option>
              </select>
              <p v-if="errors.injection_stock" class="valid-error">{{ errors.injection_stock }}</p>
            </div>

            <div class="mb-3">
              <label for="dateInput" class="form-label">date</label>
              <input type="date" class="form-control" id="dateInput" v-model="injection_date">
              <p v-if="errors.injection_date" class="valid-error">{{ errors.injection_date }}</p>
            </div>

          </div>
          <div class="modal-footer">
            <button type="submit" @click="validateForm" class="btn btn-success">Add</button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <button type="button" class="btn btn-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#reasonModal">
        {{ $translator.t('REASONS_MODAL_HEADER') }}
      </button>

      <div class="modal fade" id="reasonModal" tabindex="-1" aria-labelledby="reasonModalLabel"
           aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="reasonModalLabel">{{ $translator.t('REASONS_MODAL_HEADER') }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body reasons">
              <h2 class="fs-5">
                <span v-if="reason_id">{{ $translator.t('EDIT') }}</span>
                <span v-else>{{ $translator.t('ADD') }}</span>
                {{ $translator.t('REASON') }}
              </h2>

              <p v-if="valid_success.reason_message" class="valid-success">{{ valid_success.reason_message }}</p>

              <div class="mb-4">
                <div class="mb-3">
                  <input type="text" class="form-control" id="reasonTitleInput" :placeholder="$translator.t('TITLE')"
                         v-model="reason_title" required>
                  <p v-if="errors.reason_title" class="valid-error">{{ errors.reason_title }}</p>
                </div>

                <div class="mb-3">
                  <div class="form-check">
                    <label class="form-check-label" for="reasonIsMainInput">{{ $translator.t('SET_MAIN') }}</label>
                    <input class="form-check-input" type="checkbox" value="" id="reasonIsMainInput"
                           v-model="reason_is_main"
                           :disabled="(reason_is_main && reason_id !== null) || reasons.length === 0">
                  </div>
                  <p v-if="errors.reason_is_main" class="valid-error">{{ errors.reason_is_main }}</p>
                </div>

                <div class="mb-3">
                  <div class="btn-group" role="group">
                    <div v-if="reason_id">
                      <button type="submit" @click="patchReasons(reason_id)" class="btn btn-primary btn-sm">
                        {{ $translator.t('SAVE') }}
                      </button>
                    </div>
                    <div v-else>
                      <button type="submit" @click="postReasons" class="btn btn-success btn-sm">
                        {{ $translator.t('ADD') }}
                      </button>
                    </div>

                    <button type="submit" @c{lick="clearReasonForm" class="btn btn-secondary btn-sm">
                      {{ $translator.t('CLEAR') }}
                    </button>
                  </div>
                </div>
              </div>

              <hr>

              <div class="mb-3">
                <table class="table">
                  <tbody>
                  <tr v-for="reason in reasons" :key="reason.id">
                    <td class="align-middle">
                      <span v-if="reason.isMain"><u><b>{{ reason.title }}</b></u></span>
                      <span v-else>{{ reason.title }}</span>
                    </td>
                    <td class="align-middle reason-btn-right">
                      <div class="dropdown">
                        <button
                            class="btn btn-secondary dropdown-toggle btn-sm"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                          {{ $translator.t('MORE') }}
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <button type="button" class="dropdown-item"
                                    @click="initializeUpdateReason(reason.id, reason.title, reason.isMain)">
                              {{ $translator.t('EDIT') }}
                            </button>
                          </li>
                          <li v-if="!reason.isMain">
                            <button type="button" class="dropdown-item" @click="deleteReasons(reason.id)">
                              {{ $translator.t('DELETE') }}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <table class="table">
      <thead>
      <tr>
        <th scope="col">Date</th>
        <th scope="col">Units</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="injection in injections" :key="injection.id">
        <td>
          <button type="button" class="btn btn-link btn-sm" data-bs-toggle="modal"
                  :data-bs-target="'#detailsInjectionModal_' + injection.id">
            {{ injection.created }}
          </button>

          <div class="modal fade" :id="'detailsInjectionModal_' + injection.id" tabindex="-1"
               aria-labelledby="detailsInjectionModalLabel"
               aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="detailsInjectionModalLabel">Details {{ injection.created }}</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <p>Date: <b>{{ injection.created }}</b></p>
                  <p>Units: <b>{{ injection.numberOfUnits }}</b>j.</p>
                  <p class="faster_injection" v-if="injection.fasterInjection">Reason: <b>{{ injection.reason }}</b></p>
                  <p v-else>Reason: <b>{{ injection.reason }}</b></p>
                  <p>Faster injection: <b>{{ injection.fasterInjection }}</b></p>
                  <hr>
                  <p class="stock-label">Stock:</p>
                  <p><b>{{ (injection.stock.total - injection.stock.used) / 2000 }}</b> serving left</p>
                  <p>Company: <b>{{ injection.stock.company }}</b></p>
                  <p>Delivery: <b>{{ formatDate(injection.stock.delivery['date']) }}</b></p>
                  <p>Current supplies: <b>{{ injection.stock.total - injection.stock.used }}</b>j.</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
                </div>
              </div>
            </div>
          </div>

        </td>

        <td>
          <span class="faster_injection" v-if="injection.fasterInjection">{{ injection.numberOfUnits }}</span>
          <span v-else>{{ injection.numberOfUnits }}</span>
        </td>

        <td>
          <button type="button" class="btn btn-secondary btn-sm" data-bs-toggle="modal"
                  :data-bs-target="'#deleteInjectionModal_' + injection.id">
            X
          </button>

          <div class="modal fade" :id="'deleteInjectionModal_' + injection.id" tabindex="-1"
               aria-labelledby="deleteInjectionModalLabel"
               aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="deleteInjectionModalLabel">Delete Injection</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <p style="font-size: large"><b>Are you sure you want to delete this injection:</b></p>
                  <p>units: <b>{{ injection.numberOfUnits }}</b></p>
                  <p>date: <b>{{ injection.created }}</b></p>
                  <p>faster: <b>{{ injection.fasterInjection }}</b></p>
                  <p>reason: <b>{{ injection.reason }}</b></p>
                  <p>stock: <b>{{ injection.stock.used }}/{{ injection.stock.total }}
                    - {{ injection.stock.company }}</b></p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-danger" @click="deleteInjections(injection.id)"
                          data-bs-dismiss="modal">
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import getStocks from "@/composables/stock/getStocks";
import getInjections from "@/composables/injection/getInjections";
import postInjections from "@/composables/injection/postInjections";
import deleteInjections from "@/composables/injection/deleteInjections";
import getReasons from "@/composables/reason/getReasons";
import deleteReasons from "@/composables/reason/deleteReasons";
import postReasons from "@/composables/reason/postReasons";
import patchReasons from "@/composables/reason/patchReasons";

export default {
  data() {
    return {
      injections: [],
      stocks: [],
      injection_stock: null,
      injection_units: 2000,
      injection_reason: null,
      injection_reason_description: null,
      injection_date: null,

      reasons: [],
      reason_id: null,
      reason_title: null,
      reason_is_main: false,

      errors: {},
      valid_success: {}
    }
  },
  methods: {
    postInjections() {
      postInjections({
        created: this.injection_date,
        numberOfUnits: this.injection_units,
        reasonId: this.injection_reason,
        reasonDescription: this.injection_reason_description,
        stockId: this.injection_stock,
      })
          .then(() => {
            getInjections()
                .then(result => this.injections = result)
                .catch(err => console.log(err.message))
            this.injection_units = 2000
            this.injection_reason = null
            this.injection_reason_description = null
            this.injection_date = null
          })
          .catch(err => console.log(err.message))
    },
    deleteInjections(injectionId) {
      deleteInjections(injectionId)
          .then(() => {
            getInjections()
                .then(result => this.injections = result)
                .catch(err => console.log(err.message))
          })
          .catch(err => console.log(err.message))
    },
    postReasons() {
      postReasons({
        title: this.reason_title,
        isMain: this.reason_is_main
      })
          .then(() => {
            getReasons()
                .then(result => this.reasons = result)
                .catch(err => console.log(err.message))
            this.reason_title = null
            this.reason_is_main = false
            this.valid_success.reason_message = $translator.t('REASON_ADDED')
          })
          .catch(err => console.log(err.message))
    },
    patchReasons(id) {
      patchReasons(id, {
        title: this.reason_title,
        isMain: !!this.reason_is_main
      })
          .then(() => {
            getReasons()
                .then(result => this.reasons = result)
                .catch(err => console.log(err.message))
            this.reason_id = null
            this.reason_title = null
            this.reason_is_main = false
            this.valid_success.reason_message = $translator.t('REASON_EDITED')
          })
          .catch(err => console.log(err.message))
    },
    deleteReasons(reasonId) {
      deleteReasons(reasonId)
          .then(() => {
            getReasons()
                .then(result => this.reasons = result)
                .catch(err => console.log(err.message))
            this.valid_success.reason_message = $translator.t('REASON_DELETED')
          })
          .catch(err => console.log(err.message))
    },
    initializeUpdateReason(id, title, is_main) {
      this.reason_id = id
      this.reason_title = title
      this.reason_is_main = is_main
      this.valid_success.reason_message = null
    },
    clearReasonForm() {
      this.reason_id = null
      this.reason_title = null
      this.reason_is_main = false
      this.valid_success.reason_message = null
    },
    validateForm() {
      this.errors = {};
      this.valid_success = {};
      if (!this.injection_stock) this.errors.injection_stock = "Stock is required.";
      if (!this.injection_units) this.errors.injection_units = "Number of units is required.";
      if (!this.injection_reason && !this.injection_reason_description) this.errors.injection_reason = "Reason is required.";
      if (!this.injection_date) this.errors.injection_date = "Date is required.";

      if (Object.keys(this.errors).length === 0) {
        this.postInjections();
        this.valid_success.message = "Injection was added"
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // format YYYY-MM-DD
    },
    setDefaultReason() {
      const mainReason = this.reasons.find(reason => reason.isMain);
      if (mainReason) {
        this.injection_reason = mainReason.id;
      }
    }
  },
  created() {
    getStocks()
        .then(result => {
          if (result.length === 1) {
            this.stocks = result
            this.injection_stock = result[0].id;
          } else {
            this.stocks = result
          }
        })
        .catch(err => console.log(err.message))
    getInjections()
        .then(result => this.injections = result)
        .catch(err => console.log(err.message))
    getReasons()
        .then(result => {
          this.reasons = result
          if (this.reasons.length === 0) {
            this.reason_is_main = true
          }
          this.setDefaultReason();
        })
        .catch(err => console.log(err.message))
  },
  computed: {
    isDisabled() {
      if (this.injection_reason_description) {
        return this.injection_reason_description.trim().length > 0;
      }
      return false
    }
  }
};
</script>

<style>
.stock-label {
  font-size: large;
}

.faster_injection {
  color: red;
}

.valid-success {
  font-weight: bold;
}

.reasons {
  text-align: left;
}

.reason-btn-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}
</style>
