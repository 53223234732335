<template>
  <div>
    <h1>Panel</h1>
    <div class="settings">
      <div class="row">
        <div class="col-md-4">

          <div class="row mb-3">
            <div class="col-md-6">
              <div class="form-check form-switch">
                <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="darkModeSwitch"
                    v-model="isDarkMode"
                    @change="toggleDarkMode">
                <label class="form-check-label" for="darkModeSwitch">Dark mode</label>
              </div>
            </div>

            <div class="col-md-6">
              <select class="form-select form-select-sm" aria-label="Language select"
                      v-model="language"
                      @change="updateLanguage">
                <option>PL</option>
                <option>EN</option>
              </select>
            </div>
          </div>

          <hr>

          <div class="mb-4">
            <div class="input-group">
              <button class="btn btn-secondary" type="button" id="inputEmail"
                      @click="patchUserEmail"
                      :disabled="newEmail === user.email">Change email
              </button>
              <input type="text" class="form-control" aria-label="Change email" aria-describedby="inputEmail"
                     v-model="newEmail">
            </div>
            <div v-if="errors.email" id="emailHelpBlock" class="form-text help-block">
              {{ errors.email }}
            </div>
          </div>

          <div class="mb-4">
            <div class="input-group">
              <button class="btn btn-secondary" type="button" id="inputFrequency"
                      @click="patchUserFrequency"
                      :disabled="newFrequency === user.frequency">Change frequency
              </button>
              <input type="number" class="form-control" aria-label="Change frequency" aria-describedby="inputFrequency"
                     v-model="newFrequency">
            </div>
            <div v-if="errors.frequency" id="frequencyHelpBlock" class="form-text help-block">
              {{ errors.frequency }}
            </div>
          </div>

          <div class="mb-4">
            <div class="mb-2">
              <b>Change password</b>
            </div>
            <div class="mb-3">
              <label for="inputPassword" class="form-label">New password</label>
              <div class="input-group">
                <input
                    :type="showPassword ? 'text' : 'password'"
                    id="inputPassword"
                    class="form-control"
                    aria-describedby="passwordHelpBlock"
                    v-model="newPassword"
                />
                <button
                    class="btn btn-outline-secondary"
                    type="button"
                    :disabled="!newPassword"
                    @click="togglePasswordVisibility"
                >
                  {{ showPassword ? 'Hide' : 'Show' }}
                </button>
              </div>
              <div v-if="errors.newPassword" id="passwordHelpBlock" class="form-text help-block">
                {{ errors.newPassword }}
              </div>
            </div>
            <div class="mb-3">
              <label for="inputConfirmPassword" class="form-label">Confirm password</label>
              <div class="input-group">
                <input
                    :type="showConfirmPassword ? 'text' : 'password'"
                    id="inputConfirmPassword"
                    class="form-control"
                    aria-describedby="confirmPasswordHelpBlock"
                    v-model="confirmationPassword"
                    :disabled="!isPasswordValid"
                />
                <button
                    class="btn btn-outline-secondary"
                    type="button"
                    :disabled="!isPasswordValid"
                    @click="toggleConfirmPasswordVisibility"
                >
                  {{ showConfirmPassword ? 'Hide' : 'Show' }}
                </button>
              </div>
              <div v-if="errors.confirmationPassword" id="confirmPasswordHelpBlock" class="form-text help-block">
                {{ errors.confirmationPassword }}
              </div>
            </div>

            <ul class="password-requirements mt-2" v-if="newPassword !== null">
              <li :class="{'text-success': hasLowercase, 'text-danger': !hasLowercase}">
                Lower-case letter
              </li>
              <li :class="{'text-success': hasUppercase, 'text-danger': !hasUppercase}">
                Upper-case letter
              </li>
              <li :class="{'text-success': hasNumber, 'text-danger': !hasNumber}">
                Number
              </li>
              <li :class="{'text-success': hasSpecialChar, 'text-danger': !hasSpecialChar}">
                Special character [@.$#!%*?-&^]
              </li>
              <li :class="{'text-success': hasValidLength, 'text-danger': !hasValidLength}">
                At least 12 characters.
              </li>
              <li v-if="!['', null].includes(confirmationPassword)"
                  :class="{'text-success': hasValidConfirm, 'text-danger': !hasValidConfirm}">
                The passwords match
              </li>
            </ul>

            <div class="mb-3">
              <button
                  class="btn btn-secondary"
                  @click="patchUserPassword"
                  :disabled="!isPasswordValid || !hasValidConfirm">
                Change password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="changes-success" v-if="changes_success">{{ changes_success }}</p>
  </div>
</template>

<script>
import {ref} from "vue";
import getUserDetails from "@/composables/user/getUserDetails";
import patchUserPassword from "@/composables/user/patchUserPassword";
import patchUserFrequency from "@/composables/user/patchUserFrequency";
import patchUserEmail from "@/composables/user/patchUserEmail";
import patchUserDarkmode from "@/composables/user/patchUserDarkmode";
import patchUserLanguage from "@/composables/user/patchUserLanguage";

export default {
  data() {
    return {
      user: {
        email: null,
        frequency: null,
        language: null,
        dark_mode: null
      },
      isDarkMode: ref(false),
      language: null,
      newEmail: null,
      newFrequency: null,
      newPassword: null,
      confirmationPassword: null,
      showPassword: false,
      showConfirmPassword: false,
      errors: {},
      changes_success: null
    };
  },
  methods: {
    patchUserDarkmode() {
      patchUserDarkmode({
        darkmode: this.isDarkMode
      })
          .then(() => console.log('dark mode changed'))
          .catch(err => console.log(err))
    },
    updateLanguage() {
      this.user.language = this.language
      this.patchUserLanguage()
    },
    patchUserLanguage() {
      patchUserLanguage({
        language: this.language.toLowerCase()
      })
          .then(() => {
            localStorage.setItem('user_language', this.language.toLowerCase())
            this.$translator.changeLanguage(this.language.toLowerCase());
          })
          .catch(err => console.log(err))
    },
    patchUserEmail() {
      let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (
          this.newEmail !== this.user.email &&
          this.newEmail.match(regexEmail)
      ) {
        patchUserEmail({
          email: this.newEmail
        })
            .then(() => {
              this.changes_success = "Email was changed successfully"
              this.errors.email = null
              this.user.email = this.newEmail
            })
            .catch(err => {
              this.errors.email = err
            })
      } else {
        this.errors.email = "This isn't email."
      }
    },
    patchUserFrequency() {
      if (this.newFrequency !== this.user.frequency) {
        patchUserFrequency({
          frequency: this.newFrequency
        })
            .then(() => {
              this.changes_success = "Frequency was changed successfully"
              this.errors.frequency = null
              this.user.frequency = this.newFrequency
            })
            .catch(err => {
              this.errors.frequency = err
            })
      }
    },
    patchUserPassword() {
      if (
          this.newPassword !== null &&
          this.confirmationPassword !== null &&
          this.newPassword === this.confirmationPassword
      ) {
        patchUserPassword({
          newPassword: this.newPassword,
          confirmPassword: this.confirmationPassword,
        })
            .then(() => {
              this.changes_success = "Password was changed successfully"
              this.errors.newPassword = null
              this.errors.confirmationPassword = null
              this.newPassword = null
              this.confirmationPassword = null
            })
            .catch(err => {
              this.errors.newPassword = err
            })
      } else {
        this.errors.confirmationPassword = "Confirmation password must be at the same as new password.";
      }
    },
    toggleDarkMode() {
      if (this.isDarkMode) {
        document.documentElement.setAttribute("data-bs-theme", "dark");
        localStorage.setItem("dark_mode", "true");
      } else {
        document.documentElement.setAttribute("data-bs-theme", "light");
        localStorage.setItem("dark_mode", "false");
      }

      this.user.dark_mode = this.isDarkMode;

      this.patchUserDarkmode();
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
  },
  created() {
    getUserDetails()
        .then(result => {
          this.user = result;
          this.newEmail = result.email;
          this.newFrequency = result.frequency;
          this.language = result.language.toUpperCase();

          this.user.dark_mode = result.dark_mode;
          this.isDarkMode = result.dark_mode;

          if (this.isDarkMode) {
            document.documentElement.setAttribute("data-bs-theme", "dark");
            localStorage.setItem("dark_mode", "true");
          } else {
            document.documentElement.setAttribute("data-bs-theme", "light");
            localStorage.setItem("dark_mode", "false");
          }
        })
        .catch(err => console.log(err.message));
  },
  computed: {
    hasLowercase() {
      return this.newPassword !== null && /[a-z]/.test(this.newPassword);
    },
    hasUppercase() {
      return this.newPassword !== null && /[A-Z]/.test(this.newPassword);
    },
    hasNumber() {
      return this.newPassword !== null && /\d/.test(this.newPassword);
    },
    hasSpecialChar() {
      return this.newPassword !== null && /[@.#$!%*?\-&^]/.test(this.newPassword);
    },
    hasValidLength() {
      return this.newPassword.length >= 12
    },
    hasValidConfirm() {
      return this.newPassword !== null &&
          this.confirmationPassword !== null &&
          this.newPassword === this.confirmationPassword
    },
    isPasswordValid() {
      return (
          this.hasLowercase &&
          this.hasUppercase &&
          this.hasNumber &&
          this.hasSpecialChar &&
          this.hasValidLength
      );
    }
  },
  watch: {
    newFrequency(value) {
      if (value <= 0) {
        this.newFrequency = null
      } else if (value !== null && value !== this.user.frequency) {
        this.newFrequency = Number(value).valueOf()
      }
    },
  },
  mounted() {
    const darkModeSetting = localStorage.getItem("dark_mode");
    if (darkModeSetting === "true") {
      this.isDarkMode = ref(true);
      document.documentElement.setAttribute("data-bs-theme", "dark");
    } else {
      this.isDarkMode = ref(false);
      document.documentElement.setAttribute("data-bs-theme", "light");
    }
  }
};
</script>

<style scoped>
.help-block {
  color: red;
}

.settings {
  text-align: left;
}

.changes-success {
  color: green;
  font-weight: bold;
}
</style>

