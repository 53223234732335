import { API_BASE_URL } from '@/constants/constants';

async function getStocks() {
    const token = localStorage.getItem('token');
    const response = await fetch(API_BASE_URL + '/api/stocks', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });

    return response.json();
}

export default getStocks
