<template>
  <div>
    <h1>Strokes</h1>

    <div class="row mb-3">
      <div class="col-lg-4">
        <div class="mb-3">
          <label for="dateFromInput" class="form-label">Date from</label>
          <input type="date" class="form-control" id="dateFromInput" v-model="date_from" required>
        </div>

        <div class="mb-3">
          <label for="dateToInput" class="form-label">Date to</label>
          <input type="date" class="form-control" id="dateToInput" v-model="date_to" required>
        </div>

        <button type="submit"
                class="btn btn-primary"
                @click="getStrokes()"
                :disabled="date_from === '' || date_to === ''">
          Show strokes
        </button>
      </div>
    </div>

    <p v-if="errors.message" class="error">{{ errors.message }}</p>

    <div v-if="count !== null">

      <p v-if="count === 0" class="founded"><b>No founded strokes/faster injections</b></p>

      <div v-if="count !== 0">
        <p class="founded">Founded strokes/faster injections: <b>{{ count }}</b></p>

        <table class="table">
          <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Reason</th>
            <th scope="col">Units</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="injection in injections" :key="injection.id">

            <td>
              <button type="button" class="btn btn-link btn-sm" data-bs-toggle="modal"
                      :data-bs-target="'#detailsInjectionModal_' + injection.id">
                {{ injection.created }}
              </button>

              <div class="modal fade" :id="'detailsInjectionModal_' + injection.id" tabindex="-1"
                   aria-labelledby="detailsInjectionModalLabel"
                   aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="detailsInjectionModalLabel">Details {{ injection.created }}</h1>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <p>Date: <b>{{ injection.created }}</b></p>
                      <p>Units: <b>{{ injection.numberOfUnits }}</b>j.</p>
                      <p class="faster_injection" v-if="injection.fasterInjection">Reason: <b>{{ injection.reason }}</b>
                      </p>
                      <p v-else>Reason: <b>{{ injection.reason }}</b></p>
                      <p>Stock: <b>{{ injection.stock.company }}</b></p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
                    </div>
                  </div>
                </div>
              </div>
            </td>

            <td>
              {{ injection.reason }}
            </td>

            <td>
              <span class="faster_injection" v-if="injection.fasterInjection">{{ injection.numberOfUnits }}</span>
              <span v-else>{{ injection.numberOfUnits }}</span>
            </td>

          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import getStrokes from "@/composables/stroke/getStrokes";

export default {
  data() {
    return {
      injections: [],
      date_from: '',
      date_to: '',
      count: null,
      errors: {},
    }
  },
  methods: {
    getStrokes() {
      if (!this.validateInputDates()) {
        this.errors.message = 'Dates are missing'
        return
      }
      getStrokes({
        from: this.formatDate(this.date_from),
        to: this.formatDate(this.date_to),
      })
          .then(result => {
            this.injections = result
            this.count = this.injections.length
            this.errors = {}
          })
          .catch(err => console.log(err.message))
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // format YYYY-MM-DD
    },
    validateInputDates() {
      return !(this.date_from === null ||
          this.date_from === '' ||
          this.date_to === null ||
          this.date_to === '');
    },
  }
};
</script>

<style>
.founded {
  text-align: left;
}

.error {
  text-align: left;
  color: red;
}
</style>
